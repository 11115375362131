:root {
  --loader-blue: #287bff;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
button {
  border: none;
  cursor: pointer;
}
button:disabled {
  cursor: not-allowed;
}
a {
  text-decoration: none;
  color: #00f;
}
.spin__rotate {
  animation: 1.5s linear infinite rotate;
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
.spin__loader {
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  box-shadow: inset -0.2rem 0 0 0.2rem var(--loader-blue);
}
.spin__loader-new {
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  box-shadow: inset -0.2rem 0 0 0.2rem white;
}
