/* @import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sora:wght@100;200;300;400;500;600;700;800&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("./styles/final/styles.css");
@import url("./styles/dashboard/styles/styles.css");
@import url("./styles/databoard/styles/styles.css");
@import url("./styles/sidebar/sidebarvv2.css");
@import url("./styles/sidebar/Sidenavv3.css");
@import url("./styles/report/styles/styles.css");
@import url("./styles/home/styles/homemain.css");
@import url("./styles/topbar/style.css");
@import url("./styles/homepage/styles.css");
@import url("./styles/signup/sigin.css");
:root {
  font-family: "Inter", sans-serif;
}
.india__flag {
  height: 1.6rem;
  width: 1.6rem;
}
.footer__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}
.made__in__india {
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.registered__by__lowsoot {
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.invisible {
  visibility: hidden;
}
.hide {
  display: none;
}
.Toastify__toast-container {
  font-size: 1.6rem;
}
.helvetica {
  font-family: Helvetica, sans-serif;
}
.summarygrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2.8rem;
  max-width: 120rem;
  row-gap: 2.1rem;
}
.sigin__inputdemo {
  width: 100%;
}
.color-red {
  color: red;
}
.datefilters {
  margin-bottom: 3rem;
}
.introjs-tooltiptext {
  font-size: 1.6rem;
}
