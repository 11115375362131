:root {
  font-size: 62.5%;
  --report-top-btn-color: #ffffff;
  --plain-white: #fff;
  --summary-param-bg: rgba(255, 255, 255, 1);
  --summary-params-color: rgba(39, 30, 74, 1);
  --homepage-color-fill: #f5f6fa;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.summaryparams {
  display: flex;
  gap: 2.8rem;
  margin-bottom: 2.3rem;
  margin-top: 2.3rem;
}
.summaryparam {
  width: fit-content;
  background-color: var(--plain-white);
  color: var(--summary-params-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 3.4rem;
  border-radius: 2rem;
  gap: 1rem;
  justify-content: center;
}
.summaryparam__value {
  font-size: 4rem;
  font-weight: 700;
  line-height: 4.8rem;
  letter-spacing: -0.04em;
  color: #4b4b4b;
}
.summaryparam__title {
  display: flex;
  align-items: center;
  align-self: flex-start;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.4rem;
}
.summaryparam__icon {
  aspect-ratio: 1;
  height: 2rem;
}
.homemainv2 {
  background-color: var(--homepage-color-fill);
  display: grid;
  grid-template-rows: 6rem auto;
}
.homemaincontentv2 {
  display: flex;
  flex-direction: column;
  padding-left: 8rem;
}
.homemainheaderv2 {
  font-weight: 600;
  font-size: 4.8rem;
  line-height: 4.8rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.homevizgraph__cont {
  display: flex;
  flex-direction: column;
  gap: 1.9rem;
}
.homevizgraph__singulars {
  display: flex;
  gap: 2.8rem;
}
.datefilters__head {
  margin-bottom: 2.4rem;
}
