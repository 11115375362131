:root {
  font-size: 62.5%;
  --side-icon-hover: #ffffff;
  --plain-white: #ffffff;
  --side-icon-shadow: rgba(216, 210, 252, 0.64);
  --home-button: rgba(216, 210, 252, 0.64);
  --sidenav-background: #e5e5e5;
  --topbar-logos: rgba(216, 210, 252, 0.64);
  --parameter-value: rgba(123, 123, 123, 1);
  --scope-1-fill: #30ad1b;
  --scope-2-fill: #4d0000;
  --scope-3-fill: #ff0000;
  --graph-header-text: rgba(39, 30, 74, 1);
  --graoh-bg: rgba(255, 255, 255, 1);
  --area-graph-bg: rgba(255, 255, 255, 0.5);
  --area-graph-shadow1: rgba(50, 50, 71, 0.01);
  --area-graph-shadow2: rgba(50, 50, 71, 0.06);
  --web-plat-green-color: #79cfa6;
  --web-plat-red-color: #ff706f;
  --web-plat-bg: #e5e5e5;
  --databoard-tableheader: #271e4a;
  --dropdown-color: #271e4a;
  --filter-button-color: #ca79c6;
  --filter-label-color: rgba(39, 30, 74, 0.8);
  --data-board-bg: rgba(224, 233, 244, 1);
  --data-board-table-cont: rgba(224, 233, 244, 1);
  --checkbox-pink: #ca79c6;
  --checkbox-pink-shadow: rgba(185, 178, 226, 0.64);
  --action-btn-shadow: rgba(185, 178, 226, 0.64);
  --table-header-bg: #edf4fc;
  --table-row-bottom-border: #e0e9f4;
  --table-bottom-message: rgba(39, 30, 74, 0.8);
  --copy-button-color: #ca79c6;
  --tinge-blue: #4d7cfe;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.dialog__overlay {
  height: 100%;
  width: 100%;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialog__form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.dialog__input::placeholder {
  color: black;
}
.dialog__inputslect {
  border: none;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 2.1rem;
  background: var(--plain-white);
  box-shadow: 0 0.4rem 1.5rem rgba(185, 178, 226, 0.64);
  border-radius: 0.8rem;
  width: 100%;
  height: 4.4rem;
  color: var(--dropdown-color);
  margin-right: 1.5rem;
  padding: 1rem;
}
.dialog__inputdate {
  border: none;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 2.1rem;
  background: var(--plain-white);
  box-shadow: 0 0.4rem 1.5rem rgba(185, 178, 226, 0.64);
  border-radius: 0.8rem;
  width: 100%;
  height: 4.4rem;
  color: var(--dropdown-color);
  margin-right: 1.5rem;
  padding: 1rem;
}
.dialog__formerr {
  text-align: end;
  color: red;
  font-size: 1.6rem;
}
.dialog__inputdate {
  font-size: 1.6rem;
  width: 100%;
}
.dialog {
  border-radius: 2rem;
  background-color: var(--plain-white);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}
.dialog__header {
  text-align: center;
}
.dialog__btn {
  padding: 0.5rem 1rem;
  background-color: var(--tinge-blue);
  border: none;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: var(--plain-white);
  border-radius: 0.5rem;
}
.dialog__inputlabel {
  font-size: 1.6rem;
}
.dialog__input::placeholder {
  color: black;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.dialog__copyconttext {
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 2.1rem;
}
.dialog__copycontbtn {
  font-size: 1.4rem;
  background-color: inherit;
  /* background-color: var(--tinge-blue); */
  box-shadow: 0 0 0 0.1rem var(--tinge-blue);
  color: var(--tinge-blue);
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}
.dialog__copycont {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}
.sheets_instructions {
  word-wrap: break-word;
  max-width: 50rem;
  font-size: 1.4rem;
}
.datainmodal__buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.sheets_instructionshead {
  font-size: 1.8rem;
}
