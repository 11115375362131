:root {
  font-size: 62.5%;
  --side-icon-hover: #ffffff;
  --side-icon-shadow: rgba(216, 210, 252, 0.64);
  --home-button: rgba(216, 210, 252, 0.64);
  --sidenav-background: #e5e5e5;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.sidenavv2__logocont {
  margin-left: auto;
  margin-right: auto;
  width: min-content;
  height: min-content;
}
.sidenavv2__logoimg {
  width: 5rem;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}
.sidenavv2__logo {
  text-align: center;
  margin-top: 6rem;
}
.sidenavv2__logopara {
  font-size: 1.8rem;
  font-weight: 400;
}
.sidenavv2 {
  background: linear-gradient(
    to bottom,
    #efedfe,
    #f1edfd,
    #f2edfc,
    #f3eefb,
    #f4eefa,
    #f6eff9,
    #f8eff8,
    #f9f0f7,
    #fbf1f6,
    #fcf1f5,
    #fdf2f4,
    #fdf3f3
  );
}
.sidenavv2__navigate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  /* margin-top: 5rem; */
  position: sticky;
  top: 5rem;
}
.sidenavv2__navigateitm {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
}
.sidenavv2__navigateitmimg {
  width: 100%;
}
.sidenavv2__navigateitmlogo {
  height: 2.6rem;
  width: 2.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidenavv2__navigateitmdd {
  display: block;
  /* position: absolute; */
  /* left: 32%; */
  margin-bottom: 1rem;
  width: 100%;
  padding: 0 3rem 0 1.9rem;
  font-size: 1.8rem;
  height: 4rem;
  display: flex;
  gap: 1rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  background: var(--side-icon-hover);
  height: 6.4rem;
  box-shadow: 0 0.4rem 1.5rem var(--side-icon-shadow);
}
.sidenavv2__homemenu {
  margin-top: 8rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.4rem;
  height: 6.4rem;
  background: var(--side-icon-hover);
  box-shadow: 0 0.4rem 1.5rem var(--home-button);
  border-radius: 2rem;
  margin-bottom: 4rem;
  overflow: hidden;
}
.sideLink:active,
.sideLink:hover,
.sideLink:visited,
.sideLink:link {
  color: rgba(39, 30, 74, 1);
}
.sidenavv2__dditm {
  display: flex;
  width: 80%;
  font-size: 1.6rem;
  padding: 0.5rem 1rem;
  gap: 1.5rem;
  justify-content: flex-start;
  background-color: lightblue;
  align-items: center;
  /* background: var(--side-icon-hover); */
  /* height: 6.4rem; */
  box-shadow: 0 0.2rem 0.2rem var(--side-icon-shadow);

  border-radius: 1.5rem;
}
.sidenavv2__navigatedd {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
.sidenavv2__dditmimg {
  width: 100%;

  height: 100%;
  aspect-ratio: 1;
  height: 1.6rem;
}
.sidenavv2__dditmlogo {
  aspect-ratio: 1;
}
